export type VultronConfig = NonNullable<Window["VULTRON_CONFIG"]>;

const defaultConfig: VultronConfig = {
  apiUrl: process.env.REACT_APP_BASE_URL || "",
};

const config: VultronConfig = {
  ...defaultConfig,
  ...window.VULTRON_CONFIG,
};
export const { apiUrl } = config;
export default config;
