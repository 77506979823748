/** @jsxImportSource @emotion/react */

import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import { groupBy } from "lodash";
import { StepValue } from "../../types";
import { Extraction, ExtractionStatus } from "components/copilot/CopilotSchemaTypes";
import { setEditableTemplateRowState } from "store/reducers/extract/CurrentExtractionReducer";
import DraftRow, { SortableItem } from "./draft-row";
import useExtractionOperations from "hook/useExtractionOperations";
import { GripVertical, LucidePlus } from "lucide-react";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import { useDrag } from "./hooks";
import { verticalSortableListCollisionDetection } from "pages/draft-volume/draft-volume-sidebar/utils";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { ToImmutable } from "YJSProvider/LiveObjects";
import ContextBankManager from "./context-bank-manager";
import RequirementList from "./RequirementList";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { isInstantDraftStarted } from "../../utils";
import RequirementsListHeader from "./RequirementsListHeader";
import tw from "twin.macro";
import { Empty } from "components/molecules/empty";
import { useSelection } from "../../document-display/SelectionContext";
import { pluralizeWord } from "utils/string";

const TemplateManager = () => {
  const dispatch = useAppDispatch();
  const { addNewVolume } = useExtractionOperations();
  const { selectedBlocks } = useSelection();
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const isReadOnly =
    extraction?.status === ExtractionStatus.Completed || isInstantDraftStarted(extraction?.instantDraftConfig?.status);
  const isRequirementsStep = extraction?.step === StepValue.Requirements;
  const isImportStep = extraction?.step === StepValue.Review;
  const groupedComplianceMatrix: Record<string, ToImmutable<Extraction["compliance_matrix"]>> = useMemo(() => {
    if (!isRequirementsStep && !isImportStep) return {};
    return groupBy(
      (
        extraction?.compliance_matrix.filter(
          (row) => !row.requirement.soft_deleted && !!row.proposal_reference.section_id && !row.requirement.skipped
        ) || []
      ).sort((a, b) => (a.requirement.section_order || 0) - (b.requirement.section_order || 0)),
      (row) => row.proposal_reference.section_id
    );
  }, [extraction?.compliance_matrix, isRequirementsStep, isImportStep]);

  const formattedDrafts = useMemo(() => {
    const drafts = extraction?.framework?.volumes || [];

    return drafts.map((vol) => ({
      ...vol,
      sections:
        vol?.sections?.reduce<FormattedSection[]>((acc, section) => {
          if (!section.parent_id) {
            const subsections = vol?.sections?.filter(({ parent_id }) => parent_id === section.id) || [];
            return [...acc, { ...section, subsections }];
          }

          return acc;
        }, []) || [],
    }));
  }, [extraction?.framework?.volumes]);

  const { sensors, handleDragEnd, handleDragStart, handleDragCancel, activeDragId, dragDrafts } = useDrag(
    formattedDrafts,
    extraction?.id
  );

  const activeDraft = useMemo(() => dragDrafts.find((draft) => draft.id === activeDragId), [activeDragId, dragDrafts]);

  const flattenedSections = useMemo(
    () =>
      dragDrafts.map((draft) => ({
        ...draft,
        sections: draft.sections.flatMap((section) => [section, ...(section.subsections || [])]),
      })),
    [dragDrafts]
  );

  if (!extraction) return null;
  return (
    <div className="flex flex-1 flex-col relative h-full overflow-hidden">
      <div className="flex-1 flex relative h-full overflow-hidden">
        <div className="flex flex-col gap-4 flex-1 w-full">
          <DndContext
            sensors={sensors}
            collisionDetection={verticalSortableListCollisionDetection}
            onDragEnd={(event) => handleDragEnd(event)}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
          >
            <div className="h-full flex flex-row">
              <PanelGroup direction="horizontal">
                <Panel defaultSize={55} minSize={20}>
                  <div className="relative pb-16 overflow-y-auto h-full" css={[isReadOnly && tw`pl-2`]}>
                    {!dragDrafts.length && !isReadOnly && (
                      <div className="px-2 pt-2">
                        <button
                          onClick={() => {
                            if (extraction?.id) {
                              const createdDraft = addNewVolume(extraction.id);
                              setTimeout(
                                () =>
                                  dispatch(
                                    setEditableTemplateRowState({
                                      localValue: createdDraft.title,
                                      id: createdDraft.id,
                                    })
                                  ),
                                80
                              );
                            }
                          }}
                          className="flex items-center gap-1.5 border border-gray-400 text-sm px-2 py-1 bg-gray-100 w-full text-gray-600 rounded-md duration-100 hover:border-gray-600 hover:text-gray-800 hover:bg-gray-200"
                        >
                          <LucidePlus size={16} />
                          New Volume
                        </button>
                      </div>
                    )}
                    {!!dragDrafts.length && (
                      <div>
                        <SortableContext
                          id="EXTRACTION_TEMPLATE_DRAFTS"
                          items={dragDrafts || []}
                          strategy={verticalListSortingStrategy}
                        >
                          {dragDrafts?.map((draft) => (
                            <SortableItem key={draft.id} draft={draft} />
                          ))}
                          <DragOverlay style={{ transformOrigin: "0 0 " }}>
                            {!!activeDragId && activeDraft && <DraftRow draft={activeDraft} isDragging />}
                          </DragOverlay>
                        </SortableContext>
                      </div>
                    )}
                  </div>
                </Panel>
                <PanelResizeHandle className="z-[15] relative group flex justify-center">
                  <div className="z-[15] absolute top-14 py-0.5 -left-[6.5px] rounded bg-slate-800 hover:bg-gray-darkest">
                    <GripVertical size={14} className="text-white" />
                  </div>
                  <div className="w-px h-full bg-gray-200 delay-300 duration-150 group-hover:bg-slate-800 group-hover:scale-x-[2.5]" />
                </PanelResizeHandle>
                <Panel className="relative">
                  {!!selectedBlocks?.length && (
                    <div className="bg-blue-400 text-white text-xs p-1 flex items-center justify-center">
                      Hover below to assign {selectedBlocks.length}{" "}
                      {pluralizeWord(selectedBlocks.length, "requirement")}
                    </div>
                  )}

                  {!!selectedBlocks?.length && (
                    <div className="absolute inset-0 border-4 border-dashed border-blue-400 pointer-events-none z-10" />
                  )}

                  <div
                    className="overflow-y-auto relative"
                    id="template-manager-section-scroll"
                    css={[!!selectedBlocks?.length ? tw`h-[calc(100%-28px)]` : tw`h-full`, { zIndex: 5 }]}
                  >
                    {!flattenedSections.length && (
                      <div className="px-8 h-full">
                        <Empty
                          heading="No Requirements Assigned"
                          title="Begin by creating an outline and assigning requirements from the document"
                        />
                      </div>
                    )}
                    {flattenedSections.map(({ sections, id: volumeId, title: volumeTitle }) => (
                      <div key={volumeId} className="flex flex-col relative" id={`template-manager-volume-${volumeId}`}>
                        <RequirementsListHeader
                          volumeId={volumeId}
                          extractionId={extraction?.id}
                          title={volumeTitle}
                          isReadOnly={isReadOnly}
                        />
                        {sections.map(({ id: sectionId, title, parent_id }) => (
                          <div
                            key={sectionId}
                            className="flex flex-col relative group"
                            id={`template-manager-section-${sectionId}`}
                          >
                            <RequirementsListHeader
                              sectionId={sectionId}
                              volumeId={volumeId}
                              extractionId={extraction?.id}
                              title={title}
                              parentId={parent_id}
                              isReadOnly={isReadOnly}
                              requirementCount={groupedComplianceMatrix[sectionId]?.length || 0}
                            />
                            <RequirementList
                              requirements={groupedComplianceMatrix[sectionId] || []}
                              sectionId={sectionId}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </Panel>
              </PanelGroup>
            </div>
          </DndContext>
        </div>
      </div>
      {!isImportStep && !!extraction.framework.context_bank?.length && <ContextBankManager />}
    </div>
  );
};

export default TemplateManager;
